/**
 * = Accordions
 */

.accordion .card {
    margin-bottom: 1.5rem;
}

.accordion-panel-header {
    @include display-flex();
    @include justify-content(space-between);
    @include align-items(center);
    user-select: none;

    .icon {
        color: $dark;
        transition: $transition-base;
        i {
            font-size: $font-size-sm;
        }
    }
    .icon-title {
        margin-right: 1rem;
        i {
            margin-right: 1rem;
        }

        & + .icon {
            height: 1rem;
        }
    }

    &[aria-expanded="true"]{
        > .icon {
            @include transform(rotateZ(180deg));
        }
    }

    &[aria-expanded="true"], &:hover{
        > * {
        color: theme-color('secondary');
        @include transition($transition-base);
            i { 
                @include transition($transition-base);
                fill: theme-color('secondary');
            }
        }
    }

    &:hover {
        cursor: pointer;
    }
}
