/*

=========================================================
* Impact Design System - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/impact-design-system
* Copyright 2010 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/impact-design-system/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800&display=swap');

// Bootstrap mixins and functions
@import "bootstrap/mixins";
@import "bootstrap/functions";

// Change variables here
@import "front/variables";

// Bootstrap
@import "bootstrap/bootstrap";

// Vendor
@import "front/vendor";

// front mixins & functions
@import "front/mixins";
@import "front/functions";

// Utilities
@import "front/reboot";
@import "front/utilities";

// Layout
@import "front/layout";

// Components
@import "front/components";

// write your custom styles here!