/**steps
 * = Accordions
 */

.step{
    position: relative;
    margin-bottom: 4rem;
    border-radius: 6px;
}

// Icon (svg) organic-shapes fill colors
@each $color, $value in $theme-colors {
    .layer-#{$color}{ 
        color: rgba( $value, .15 );
    }
}