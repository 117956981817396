@mixin filter($filter) {
    -webkit-filter: #{$filter};
	-moz-filter: #{$filter};
	-ms-filter: #{$filter};
	-o-filter: #{$filter};
	filter: #{$filter};
}

@mixin backface-visibility($value) {
	-webkit-backface-visibility: $value;
	-moz-backface-visibility: $value;
	-o-backface-visibility: $value;
	backface-visibility: $value;
}

@mixin transform-style($value) {
	-webkit-transform-style: $value;
	-moz-transform-style: $value;
	-o-transform-style: $value;
	transform-style: $value;
}

@mixin perspective($value) {
	-webkit-perspective: $value;
    -moz-perspective: $value;
    -o-perspective: $value;
    perspective: $value;
}

@mixin display-flex() {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

@mixin flex($values) {
	-webkit-box-flex: $values;
	   -moz-box-flex: $values;
		-webkit-flex: $values;
		  -ms-flex: $values;
			  flex: $values;
  }
  
  @mixin flex-direction($direction) {
	-webkit-flex-direction: $direction;
	   -moz-flex-direction: $direction;
		-ms-flex-direction: $direction;
			flex-direction: $direction;
  }
  
  @mixin flex-wrap($wrap) {
	-webkit-flex-wrap: $wrap;
	   -moz-flex-wrap: $wrap;
		-ms-flex-wrap: $wrap;
			flex-wrap: $wrap;
  }
  
  @mixin flex-flow($flow) {
	-webkit-flex-flow: $flow;
	   -moz-flex-flow: $flow;
		-ms-flex-flow: $flow;
			flex-flow: $flow;
  }
  
  @mixin order($val) {
	-webkit-box-ordinal-group: $val;  
	   -moz-box-ordinal-group: $val;     
			 -ms-flex-order: $val;     
			  -webkit-order: $val;  
				  order: $val;
  }
  
  @mixin flex-grow($grow) {
	-webkit-flex-grow: $grow;
	   -moz-flex-grow: $grow;
		-ms-flex-grow: $grow;
			flex-grow: $grow;
  }
  
  @mixin flex-shrink($shrink) {
	-webkit-flex-shrink: $shrink;
	   -moz-flex-shrink: $shrink;
		-ms-flex-shrink: $shrink;
			flex-shrink: $shrink;
  }
  
  @mixin flex-basis($width) {
	-webkit-flex-basis: $width;
	   -moz-flex-basis: $width;
		-ms-flex-basis: $width;
			flex-basis: $width;
  }
  
  @mixin justify-content($justify) {
	-webkit-justify-content: $justify;
	   -moz-justify-content: $justify;
		-ms-justify-content: $justify;
			justify-content: $justify;
			  -ms-flex-pack: $justify;
  }
  
  @mixin align-content($align) {
	-webkit-align-content: $align;
	   -moz-align-content: $align;
		-ms-align-content: $align;
			align-content: $align;
  }
  
  @mixin align-items($align) {
	-webkit-align-items: $align;
	   -moz-align-items: $align;
		-ms-align-items: $align;
			align-items: $align;
  }
  
  @mixin align-self($align) {
	-webkit-align-self: $align;
	   -moz-align-self: $align;
		-ms-align-self: $align;
			align-self: $align;
  }